import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SmsIcon from '@material-ui/icons/Sms';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import HomeIcon from '@material-ui/icons/Home';
import ShareIcon from '@material-ui/icons/Share';
import html2canvas from 'html2canvas';

import moment from 'moment';
import 'moment/locale/bn-bd';

import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/components/pagination/pagination.min.css';
import './styles/styles.css';

import SwiperCore, {
  EffectCoverflow, Pagination,
} from 'swiper/core';
import useStyles from './styles/styles';

moment.locale('bn-bd');

SwiperCore.use([EffectCoverflow, Pagination]);

export default function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const bizmobile = urlParams.get('mobile');
  const bizname = urlParams.get('name');
  const bizdesc = urlParams.get('desc');
  const bizaddress = urlParams.get('address');
  const bizOwner = urlParams.get('owner');
  const customername = urlParams.get('customer');
  const customerbaki = urlParams.get('baki');
  const eventdate = urlParams.get('date');
  const card = urlParams.get('card');
  const capture = urlParams.get('capture');

  const classes = useStyles();
  const [selectedCard, setSelectedCard] = useState(card);
  const [name, setName] = useState(bizname);
  const [desc, setDesc] = useState(bizdesc);
  const [mobile, setMobile] = useState(bizmobile);
  const [address, setAddress] = useState(bizaddress);
  const [owner, setOwner] = useState(bizOwner);
  const [customer, setCustomer] = useState(customername);
  const [baki, setBaki] = useState(customerbaki);
  const [selectedDate, setSelectedDate] = React.useState(eventdate);
  const [selectedFile, setSelectedFile] = useState(capture);
  const [fbShareURL, setFBShareURL] = useState('');
  const [fbMessengerURL, setFBMessengerURL] = useState('');
  const [whatsAppURL, setWhatsAppURL] = useState('');
  const [smsURL, setSmsURL] = useState('');
  const [shareURL, setShareURL] = useState('');
  const [downloadURL, setDownloadURL] = useState('');

  let href = `?mobile=${encodeURIComponent(mobile)}`;
  if (name) href += `&name=${encodeURIComponent(name)}`;
  if (desc) href += `&desc=${encodeURIComponent(desc)}`;
  if (address) href += `&address=${encodeURIComponent(address)}`;
  if (owner) href += `&owner=${encodeURIComponent(owner)}`;
  if (selectedDate) href += `&date=${moment(selectedDate).locale('en-us').format('YYYY-MM-DD')}`;
  if (customer) href += `&customer=${encodeURIComponent(customer)}`;
  if (baki) href += `&baki=${baki}`;

  const cardURL = `/cards/${card}.png`;

  const dataURItoBlob = (dataURI, mime) => {
    const byteString = window.atob(dataURI);
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i+=1) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ia], { type: mime });
    return blob;
  };

  const handleCapture = (event) => {
    if (event.target.files.length > 0) setSelectedFile(URL.createObjectURL(event.target.files[0]));
  };

  const shareCard = async (event) => {
    let sharetext = '';
    if (event.currentTarget.dataset && event.currentTarget.dataset.sharetext) {
      sharetext = event.currentTarget.dataset.sharetext;
    }
    try {
      const shareData = {
        text: sharetext,
        url: shareURL,
      };
      await navigator.share(shareData);
    } catch (err) {
      console.log('Error: ', err);
    }
  };

  const generateCard = (event) => {
    let sharetext = '';
    if (event.currentTarget.dataset && event.currentTarget.dataset.sharetext) {
      sharetext = event.currentTarget.dataset.sharetext;
    }
    const cardType = event.currentTarget.id;
    html2canvas(document.querySelector('#finalcard'), { scale: 4, useCORS: true }).then((canvas) => {
      const dataURI = canvas.toDataURL('image/jpeg', 0.8);
      const imgdata = dataURI.match(/data:(image\/.+);base64,(.+)/);
      const blob = dataURItoBlob(imgdata[2], imgdata[1]);
      const fd = new FormData();
      fd.append('file', blob);
      fd.append('mobile', mobile);
      fd.append('shopname', name);
      fd.append('address', address);
      fd.append('owner', owner);
      fd.append('card', card);
      const request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState == XMLHttpRequest.DONE) {
          const responseJSON = JSON.parse(request.responseText);
          const downloadLink = `${process.env.REACT_APP_DIGICARDS_SERVICE_BASE_URL}/download/${responseJSON.id}/${responseJSON.token}`;
          const cardLink = `${process.env.REACT_APP_DIGICARDS_SERVICE_BASE_URL}/${cardType}/${responseJSON.id}/${responseJSON.token}`;
          const fbURL = `https://www.facebook.com/dialog/share?app_id=3029149127204754&display=popup&href=${encodeURIComponent(cardLink)}`;
          //const fbAppURL = `fb://facewebmodal/f?href=${encodeURIComponent(fbURL)}`;
          const fbMessengerAppURL = `fb-messenger://share/?link=${encodeURIComponent(cardLink)}`;
          const whatsAppAppURL = `whatsapp://send?text=${encodeURIComponent(cardLink)}`;
          const shareTextWithURL = `${sharetext} ${cardLink}`;
          const smsAppURL = `sms:?body=${encodeURIComponent(shareTextWithURL)}`;
          setFBShareURL(fbURL);
          setFBMessengerURL(fbMessengerAppURL);
          setWhatsAppURL(whatsAppAppURL);
          setSmsURL(smsAppURL);
          setShareURL(cardLink);
          setDownloadURL(downloadLink);
        }
      };
      request.open('POST', `${process.env.REACT_APP_DIGICARDS_SERVICE_BASE_URL}/upload/`);
      request.send(fd);
    });
  };

  const onSlideChange = (swiper) => {
    setSelectedCard(swiper.slides[swiper.activeIndex].id);
  };

  return (
    <Router>
      <Switch>
        <Route path="/halkhata">
          <Helmet>
            <title>হালখাতার কার্ড</title>
          </Helmet>
          <Container maxWidth="sm">
            <Box my={4}>
              {card ? (
                <div>
                  <div id="finalcard" align="center" className={classes.finalCard}>
                    <SwiperSlide><img src={cardURL} /></SwiperSlide>
                    <div className={classes.labelDate}>
                      {selectedDate ? (
                        <b>
                          তারিখ:
                          {moment(selectedDate).format('Do MMMM yyyy, রোজ dddd')}
                        </b>
                      ) : ''}
                    </div>
                    <div className={classes.labelCustomer}>{customer || ''}</div>
                    <div className={classes.labelBaki}>
                      {baki ? (
                        <b>
                          মোট বাকি:
                          <span style={{ color: '#C4161C' }}>
                            ৳
                            {baki}
                          </span>
                        </b>
                      ) : ''}
                    </div>
                    <div className={classes.labelBusiness}>
                      {name ? (
                        <span>
                          নিবেদক
                          <br />
                          <b>{name || ''}</b>
                          <br />
                          {mobile || ''}
                          <br />
                          {address || ''}
                        </span>
                      ) : ''}
                    </div>
                  </div>
                  {fbShareURL ? (
                    <div className={classes.sharebox}>
                      <Button href={downloadURL} variant="contained" className={classes.sharebtn}>
                        <DownloadIcon />&nbsp;কার্ডটি ডাউনলোড করি
                      </Button>
                      <Button href={whatsAppURL} variant="contained" className={classes.sharebtn}>
                        <WhatsAppIcon />&nbsp;হোয়াটসঅ্যাপ এ পাঠাই
                      </Button>
                      <Button href={fbMessengerURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুক মেসেঞ্জারে পাঠাই
                      </Button>
                      <Button href={fbShareURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুকে শেয়ার করি
                      </Button>
                      
                      <Button href={smsURL} variant="contained" className={classes.sharebtn}>
                        <SmsIcon />&nbsp;এসএমএস এ পাঠাই
                      </Button>
                      {navigator.share ? (
                        <Button id="sharebtn" data-sharetext="শুভ হালখাতা!📕💥" onClick={shareCard} variant="contained" className={classes.sharebtn}>
                          <ShareIcon />&nbsp;অন্য মাধ্যমে শেয়ার করি
                        </Button>
                      ) : ''}
                      <Button href={`/halkhata${href}`} variant="contained" className={classes.sharebtn}>
                        <HomeIcon />&nbsp;হোম এ যাই
                      </Button>
                    </div>
                  ) : (
                    <Button id="halkhatacard" data-sharetext="শুভ হালখাতা!📕💥" onClick={generateCard} variant="contained" className={classes.tkbtn}>
                      নিশ্চিত
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  <Swiper
                    effect="coverflow"
                    grabCursor
                    centeredSlides
                    slidesPerView="auto"
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    pagination
                    onSwiper={onSlideChange}
                    onSlideChange={onSlideChange}
                    className="mySwiper"
                  >
                    <SwiperSlide id="35"><img src="/cards/35.png" /></SwiperSlide>
                    <SwiperSlide id="36"><img src="/cards/36.png" /></SwiperSlide>
                    <SwiperSlide id="37"><img src="/cards/37.png" /></SwiperSlide>
                    <SwiperSlide id="38"><img src="/cards/38.png" /></SwiperSlide>
                    <SwiperSlide id="39"><img src="/cards/39.png" /></SwiperSlide>
                  </Swiper>
                  <div className={classes.labelDate}>
                    {selectedDate ? (
                      <b>
                        তারিখ:
                        {moment(selectedDate).format('Do MMMM yyyy, রোজ dddd')}
                      </b>
                    ) : ''}
                  </div>
                  <div className={classes.labelCustomer}>{customer || ''}</div>
                  <div className={classes.labelBaki}>
                    {baki ? (
                      <b>
                        মোট বাকি:
                        <span style={{ color: '#C4161C' }}>
                          ৳
                          {baki}
                        </span>
                      </b>
                    ) : ''}
                  </div>
                  <div className={classes.labelBusiness}>
                    {name ? (
                      <span>
                        নিবেদক
                        <br />
                        <b>{name || ''}</b>
                        <br />
                        {mobile || ''}
                        <br />
                        {address || ''}
                      </span>
                    ) : ''}
                  </div>
                  <TextField onChange={(event) => setName(event.target.value)} id="business_name" margin="normal" fullWidth label="ব্যবসা প্রতিষ্ঠানের নাম*" variant="outlined" value={name || ''} />
                  <TextField onChange={(event) => setMobile(event.target.value)} id="mobile_no" margin="normal" fullWidth label="মোবাইল নম্বর*" variant="outlined" value={mobile || ''} />
                  <TextField onChange={(event) => setAddress(event.target.value)} id="business_address" margin="normal" fullWidth label="ব্যবসা প্রতিষ্ঠানের ঠিকানা*" variant="outlined" value={address || ''} />
                  <MuiPickersUtilsProvider utils={MomentUtils} locale="bn">
                    <DatePicker onChange={(date) => setSelectedDate(date)} id="halkhata_date" margin="normal" fullWidth label="হালখাতার তারিখ*" format="DD/MM/YYYY" cancelLabel="বাতিল" okLabel="ঠিক আছে" inputVariant="outlined" value={selectedDate} />
                  </MuiPickersUtilsProvider>
                  <TextField onChange={(event) => setCustomer(event.target.value)} id="customer_name" margin="normal" fullWidth label="কাস্টমারের নাম" variant="outlined" value={customer || ''} />
                  <TextField
                    onChange={(event) => setBaki(event.target.value)}
                    id="customer_baki"
                    margin="normal"
                    fullWidth
                    label="বাকির পরিমাণ"
                    variant="outlined"
                    value={baki || ''}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">৳</InputAdornment>,
                    }}
                  />
                  <Button href={`/halkhata${href}&card=${selectedCard}`} disabled={!((name && mobile && address && selectedDate))} variant="contained" className={classes.tkbtn}>
                    পরবর্তী
                  </Button>
                </div>
              )}
            </Box>
          </Container>
        </Route>
        <Route path="/newyear">
          <Helmet>
            <title>ইংরেজি নতুন বছরের কার্ড</title>
          </Helmet>
          <Container maxWidth="sm">
            <Box my={4}>
              {card ? (
                <div>
                  <div id="finalcard" align="center" className={classes.finalCard}>
                    <SwiperSlide><img src={cardURL} /></SwiperSlide>
                    <div className={classes.labelOwnerCenter}><b>{owner || ''}</b></div>
                    <div className={classes.labelBusinessCenter}>{name || ''}</div>
                  </div>
                  {fbShareURL ? (
                    <div className={classes.sharebox}>
                      <Button href={downloadURL} variant="contained" className={classes.sharebtn}>
                        <DownloadIcon />&nbsp;কার্ডটি ডাউনলোড করি
                      </Button>
                      <Button href={fbShareURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুকে শেয়ার করি
                      </Button>
                      <Button href={whatsAppURL} variant="contained" className={classes.sharebtn}>
                        <WhatsAppIcon />&nbsp;হোয়াটসঅ্যাপ এ পাঠাই
                      </Button>
                      <Button href={fbMessengerURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুক মেসেঞ্জারে পাঠাই
                      </Button>
                      <Button href={smsURL} variant="contained" className={classes.sharebtn}>
                        <SmsIcon />&nbsp;এসএমএস এ পাঠাই
                      </Button>
                      {navigator.share ? (
                        <Button id="sharebtn" data-sharetext="নতুন বছরের শুভেচ্ছা!🥳💥" onClick={shareCard} variant="contained" className={classes.sharebtn}>
                          <ShareIcon />&nbsp;অন্য মাধ্যমে শেয়ার করি
                        </Button>
                      ) : ''}
                      <Button href={`/newyear${href}`} variant="contained" className={classes.sharebtn}>
                        <HomeIcon />&nbsp;হোম এ যাই
                      </Button>
                    </div>
                  ) : (
                    <Button id="newyearcard" data-sharetext="নতুন বছরের শুভেচ্ছা!🥳💥" onClick={generateCard} variant="contained" className={classes.tkbtn}>
                      নিশ্চিত
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  <Swiper
                    effect="coverflow"
                    grabCursor
                    centeredSlides
                    slidesPerView="auto"
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    pagination
                    onSwiper={onSlideChange}
                    onSlideChange={onSlideChange}
                    className="mySwiper"
                  >
                    <SwiperSlide id="52"><img src="/cards/52.png" /></SwiperSlide>
                    <SwiperSlide id="53"><img src="/cards/53.png" /></SwiperSlide>
                    <SwiperSlide id="54"><img src="/cards/54.png" /></SwiperSlide>
                  </Swiper>
                  <div className={classes.labelOwnerCenter}><b>{owner || ''}</b></div>
                  <div className={classes.labelBusinessCenter}>{name || ''}</div>
                  <TextField onChange={(event) => setOwner(event.target.value)} id="business_owner" margin="normal" fullWidth label="আপনার নাম*" variant="outlined" value={owner || ''} />
                  <TextField onChange={(event) => setName(event.target.value)} id="business_name" margin="normal" fullWidth label="ব্যবসা প্রতিষ্ঠানের নাম*" variant="outlined" value={name || ''} />
                  <Button href={`/newyear${href}&card=${selectedCard}`} disabled={!((name && owner))} variant="contained" className={classes.tkbtn}>
                    পরবর্তী
                  </Button>
                </div>
              )}
            </Box>
          </Container>
        </Route>
        <Route path="/feb21">
          <Helmet>
            <title>একুশে ফেব্রুয়ারির কার্ড</title>
          </Helmet>
          <Container maxWidth="sm">
            <Box my={4}>
              {card ? (
                <div>
                  <div id="finalcard" align="center" className={classes.finalCard}>
                    <SwiperSlide><img src={cardURL} /></SwiperSlide>
                    <div className={classes.labelOwnerCenter}><b>{owner || ''}</b></div>
                    <div className={classes.labelBusinessCenter}>{name || ''}</div>
                  </div>
                  {fbShareURL ? (
                    <div className={classes.sharebox}>
                      <Button href={downloadURL} variant="contained" className={classes.sharebtn}>
                        <DownloadIcon />&nbsp;কার্ডটি ডাউনলোড করি
                      </Button>
                      <Button href={fbShareURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুকে শেয়ার করি
                      </Button>
                      <Button href={whatsAppURL} variant="contained" className={classes.sharebtn}>
                        <WhatsAppIcon />&nbsp;হোয়াটসঅ্যাপ এ পাঠাই
                      </Button>
                      <Button href={fbMessengerURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুক মেসেঞ্জারে পাঠাই
                      </Button>
                      <Button href={smsURL} variant="contained" className={classes.sharebtn}>
                        <SmsIcon />&nbsp;এসএমএস এ পাঠাই
                      </Button>
                      {navigator.share ? (
                        <Button id="sharebtn" data-sharetext="অমর একুশে ফেব্রুয়ারি!🤚🔴" onClick={shareCard} variant="contained" className={classes.sharebtn}>
                          <ShareIcon />&nbsp;অন্য মাধ্যমে শেয়ার করি
                        </Button>
                      ) : ''}
                      <Button href={`/feb21${href}`} variant="contained" className={classes.sharebtn}>
                        <HomeIcon />&nbsp;হোম এ যাই
                      </Button>
                    </div>
                  ) : (
                    <Button id="feb21card" data-sharetext="অমর একুশে ফেব্রুয়ারি!🤚🔴" onClick={generateCard} variant="contained" className={classes.tkbtn}>
                      নিশ্চিত
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  <Swiper
                    effect="coverflow"
                    grabCursor
                    centeredSlides
                    slidesPerView="auto"
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    pagination
                    onSwiper={onSlideChange}
                    onSlideChange={onSlideChange}
                    className="mySwiper"
                  >
                    <SwiperSlide id="31"><img src="/cards/31.png" /></SwiperSlide>
                    <SwiperSlide id="32"><img src="/cards/32.png" /></SwiperSlide>
                    <SwiperSlide id="33"><img src="/cards/33.png" /></SwiperSlide>
                    <SwiperSlide id="34"><img src="/cards/34.png" /></SwiperSlide>
                  </Swiper>
                  <div className={classes.labelOwnerCenter}><b>{owner || ''}</b></div>
                  <div className={classes.labelBusinessCenter}>{name || ''}</div>
                  <TextField onChange={(event) => setOwner(event.target.value)} id="business_owner" margin="normal" fullWidth label="আপনার নাম*" variant="outlined" value={owner || ''} />
                  <TextField onChange={(event) => setName(event.target.value)} id="business_name" margin="normal" fullWidth label="ব্যবসা প্রতিষ্ঠানের নাম*" variant="outlined" value={name || ''} />
                  <Button href={`/feb21${href}&card=${selectedCard}`} disabled={!((name && owner))} variant="contained" className={classes.tkbtn}>
                    পরবর্তী
                  </Button>
                </div>
              )}
            </Box>
          </Container>
        </Route>
        <Route path="/eid">
          <Helmet>
            <title>ঈদ-উল-ফিতরের কার্ড</title>
          </Helmet>
          <Container maxWidth="sm">
            <Box my={4}>
              {card ? (
                <div>
                  <div id="finalcard" align="center" className={classes.finalCard}>
                    <SwiperSlide><img src={cardURL} /></SwiperSlide>
                    <div className={classes.labelOwnerLeft}><b>{owner || ''}</b></div>
                    <div className={classes.labelBusinessLeft}>{name || ''}</div>
                  </div>
                  {fbShareURL ? (
                    <div className={classes.sharebox}>
                      <Button href={downloadURL} variant="contained" className={classes.sharebtn}>
                        <DownloadIcon />&nbsp;কার্ডটি ডাউনলোড করি
                      </Button>
                      <Button href={fbShareURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুকে শেয়ার করি
                      </Button>
                      <Button href={whatsAppURL} variant="contained" className={classes.sharebtn}>
                        <WhatsAppIcon />&nbsp;হোয়াটসঅ্যাপ এ পাঠাই
                      </Button>
                      <Button href={fbMessengerURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুক মেসেঞ্জারে পাঠাই
                      </Button>
                      <Button href={smsURL} variant="contained" className={classes.sharebtn}>
                        <SmsIcon />&nbsp;এসএমএস এ পাঠাই
                      </Button>
                      {navigator.share ? (
                        <Button id="sharebtn" data-sharetext="ঈদ মোবারক!🌙" onClick={shareCard} variant="contained" className={classes.sharebtn}>
                          <ShareIcon />&nbsp;অন্য মাধ্যমে শেয়ার করি
                        </Button>
                      ) : ''}
                      <Button href={`/eid${href}`} variant="contained" className={classes.sharebtn}>
                        <HomeIcon />&nbsp;হোম এ যাই
                      </Button>
                    </div>
                  ) : (
                    <Button id="eidcard" data-sharetext="ঈদ মোবারক!🌙" onClick={generateCard} variant="contained" className={classes.tkbtn}>
                      নিশ্চিত
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  <Swiper
                    effect="coverflow"
                    grabCursor
                    centeredSlides
                    slidesPerView="auto"
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    pagination
                    onSwiper={onSlideChange}
                    onSlideChange={onSlideChange}
                    className="mySwiper"
                  >
                    <SwiperSlide id="66"><img src="/cards/66.png" /></SwiperSlide>
                    <SwiperSlide id="68"><img src="/cards/68.png" /></SwiperSlide>
                    <SwiperSlide id="69"><img src="/cards/69.png" /></SwiperSlide>
                    <SwiperSlide id="70"><img src="/cards/70.png" /></SwiperSlide>
                  </Swiper>
                  <div className={classes.labelOwnerLeft}><b>{owner || ''}</b></div>
                  <div className={classes.labelBusinessLeft}>{name || ''}</div>
                  <TextField inputProps={{ maxLength: 25 }} onChange={(event) => setOwner(event.target.value)} id="business_owner" margin="normal" fullWidth label="আপনার নাম*" variant="outlined" value={owner || ''} />
                  <TextField inputProps={{ maxLength: 55 }} onChange={(event) => setName(event.target.value)} id="business_name" margin="normal" fullWidth label="ব্যবসা প্রতিষ্ঠানের নাম*" variant="outlined" value={name || ''} />
                  <Button href={`/eid${href}&card=${selectedCard}`} disabled={!((name && owner))} variant="contained" className={classes.tkbtn}>
                    পরবর্তী
                  </Button>
                </div>
              )}
            </Box>
          </Container>
        </Route>
        <Route path="/kurbani">
          <Helmet>
            <title>ঈদ-উল-আযহার কার্ড</title>
          </Helmet>
          <Container maxWidth="sm">
            <Box my={4}>
              {card ? (
                <div>
                  <div id="finalcard" align="center" className={classes.finalCard}>
                    <SwiperSlide><img src={cardURL} /></SwiperSlide>
                    <div className={classes.labelOwnerTopRight}><b>{owner || ''}</b></div>
                    <div className={classes.labelBusinessTopRight}>{name || ''}</div>
                  </div>
                  {fbShareURL ? (
                    <div className={classes.sharebox}>
                      <Button href={downloadURL} variant="contained" className={classes.sharebtn}>
                        <DownloadIcon />&nbsp;কার্ডটি ডাউনলোড করি
                      </Button>
                      <Button href={fbShareURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুকে শেয়ার করি
                      </Button>
                      <Button href={whatsAppURL} variant="contained" className={classes.sharebtn}>
                        <WhatsAppIcon />&nbsp;হোয়াটসঅ্যাপ এ পাঠাই
                      </Button>
                      <Button href={fbMessengerURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুক মেসেঞ্জারে পাঠাই
                      </Button>
                      <Button href={smsURL} variant="contained" className={classes.sharebtn}>
                        <SmsIcon />&nbsp;এসএমএস এ পাঠাই
                      </Button>
                      {navigator.share ? (
                        <Button id="sharebtn" data-sharetext="ঈদ মোবারক!" onClick={shareCard} variant="contained" className={classes.sharebtn}>
                          <ShareIcon />&nbsp;অন্য মাধ্যমে শেয়ার করি
                        </Button>
                      ) : ''}
                      <Button href={`/kurbani${href}`} variant="contained" className={classes.sharebtn}>
                        <HomeIcon />&nbsp;হোম এ যাই
                      </Button>
                    </div>
                  ) : (
                    <Button id="kurbanicard" data-sharetext="ঈদ মোবারক!" onClick={generateCard} variant="contained" className={classes.tkbtn}>
                      নিশ্চিত
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  <Swiper
                    effect="coverflow"
                    grabCursor
                    centeredSlides
                    slidesPerView="auto"
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    pagination
                    onSwiper={onSlideChange}
                    onSlideChange={onSlideChange}
                    className="mySwiper"
                  >
                    <SwiperSlide id="60"><img src="/cards/60.png" /></SwiperSlide>
                    <SwiperSlide id="61"><img src="/cards/61.png" /></SwiperSlide>
                    <SwiperSlide id="62"><img src="/cards/62.png" /></SwiperSlide>
                    <SwiperSlide id="63"><img src="/cards/63.png" /></SwiperSlide>
                    <SwiperSlide id="64"><img src="/cards/64.png" /></SwiperSlide>
                  </Swiper>
                  <div className={classes.labelOwnerTopRight}><b>{owner || ''}</b></div>
                  <div className={classes.labelBusinessTopRight}>{name || ''}</div>
                  <TextField onChange={(event) => setOwner(event.target.value)} id="business_owner" margin="normal" fullWidth label="আপনার নাম*" variant="outlined" value={owner || ''} />
                  <TextField onChange={(event) => setName(event.target.value)} id="business_name" margin="normal" fullWidth label="ব্যবসা প্রতিষ্ঠানের নাম*" variant="outlined" value={name || ''} />
                  <Button href={`/kurbani${href}&card=${selectedCard}`} disabled={!((name && owner))} variant="contained" className={classes.tkbtn}>
                    পরবর্তী
                  </Button>
                </div>
              )}
            </Box>
          </Container>
        </Route>
        <Route path="/superqr">
          <Helmet>
            <title>সুপার QR কার্ড</title>
          </Helmet>
          <Container maxWidth="sm">
            <Box my={4}>
              {card ? (
                <div>
                  <div id="finalcard" align="center" className={classes.finalCard}>
                    <SwiperSlide><img src={cardURL} /></SwiperSlide>
                    <div className={classes.labelBusinessBottomRight}><b>{name || ''}</b></div>
                    <div className={classes.labelAddressBottomRight}>{address || ''}</div>
                    <div className={classes.imagePreview}><img src={selectedFile} className={classes.imagePreviewImg} /></div>
                  </div>
                  {fbShareURL ? (
                    <div className={classes.sharebox}>
                      <span className={classes.centerText}>#SuperQR লিখে ফেসবুকে শেয়ার করুন</span>
                      <Button href={fbShareURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুকে শেয়ার করি
                      </Button>
                      <Button href={`/superqr${href}`} variant="contained" className={classes.tkbtn}>
                        আবার ছবি তুলি
                      </Button>
                    </div>
                  ) : (
                    <Button id="superqrcard" data-sharetext="সুপার QR!" onClick={generateCard} variant="contained" className={classes.tkbtn}>
                      নিশ্চিত
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  <Swiper
                    effect="coverflow"
                    grabCursor
                    centeredSlides
                    slidesPerView="auto"
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    pagination
                    onSwiper={onSlideChange}
                    onSlideChange={onSlideChange}
                    className="mySwiper"
                  >
                    <SwiperSlide id="49"><img src="/cards/49.png" /></SwiperSlide>
                    <SwiperSlide id="50"><img src="/cards/50.png" /></SwiperSlide>
                    <SwiperSlide id="51"><img src="/cards/51.png" /></SwiperSlide>
                  </Swiper>
                  <div className={classes.labelBusinessBottomRight}><b>{name || ''}</b></div>
                  <div className={classes.labelAddressBottomRight}>{address || ''}</div>
                  <div className={classes.imagePreview}><img src={selectedFile} className={classes.imagePreviewImg} /></div>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    onChange={handleCapture}
                    type="file"
                  />
                  <label htmlFor="contained-button-file">
                    <Button variant="contained" component="span" className={classes.tkbtnupload}>
                      {selectedFile ? 'ছবি তোলা হয়েছে' : 'সুপার QR কোডের ছবি তুলুন*'}
                    </Button>
                  </label>
                  <TextField onChange={(event) => setName(event.target.value)} id="business_name" margin="normal" fullWidth label="ব্যবসা প্রতিষ্ঠানের নাম*" variant="outlined" value={name || ''} />
                  <TextField onChange={(event) => setAddress(event.target.value)} id="business_address" margin="normal" fullWidth label="ব্যবসা প্রতিষ্ঠানের ঠিকানা*" variant="outlined" value={address || ''} />
                  <Button href={`/superqr${href}&card=${selectedCard}&capture=${selectedFile}`} disabled={!((name && address && selectedFile))} variant="contained" className={classes.tkbtn}>
                    পরবর্তী
                  </Button>
                </div>
              )}
            </Box>
          </Container>
        </Route>
        <Route path="/biz">
          <Helmet>
            <title>ব্যবসা প্রতিষ্ঠানের কার্ড</title>
          </Helmet>
          <Container maxWidth="sm">
            <Box my={4}>
              {card ? (
                <div>
                  <div id="finalcard" align="center" className={classes.finalCard}>
                    <SwiperSlide><img src={cardURL} /></SwiperSlide>
                    <div className={classes.labelOwnerTopMostRight}><b>{owner || ''}</b></div>
                    <div className={classes.labelMobileTopRight}>{mobile || ''}</div>
                    <div className={classes.labelBusinessBottomCenter}><b>{name || ''}</b></div>
                    <div className={classes.labelDescBottomCenter}>{desc || ''}</div>
                    <div className={classes.labelAddressBottomCenter}>{address || ''}</div>
                    <div className={classes.imagePreviewQR}><img src={selectedFile} className={classes.imagePreviewQRImg} /></div>
                  </div>
                  {fbShareURL ? (
                    <div className={classes.sharebox}>
                      <Button href={downloadURL} variant="contained" className={classes.sharebtn}>
                        <DownloadIcon />&nbsp;কার্ডটি ডাউনলোড করি
                      </Button>
                      <Button href={fbShareURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুকে শেয়ার করি
                      </Button>
                      <Button href={whatsAppURL} variant="contained" className={classes.sharebtn}>
                        <WhatsAppIcon />&nbsp;হোয়াটসঅ্যাপ এ পাঠাই
                      </Button>
                      <Button href={fbMessengerURL} variant="contained" className={classes.sharebtn}>
                        <FacebookIcon />&nbsp;ফেসবুক মেসেঞ্জারে পাঠাই
                      </Button>
                      <Button href={smsURL} variant="contained" className={classes.sharebtn}>
                        <SmsIcon />&nbsp;এসএমএস এ পাঠাই
                      </Button>
                      {navigator.share ? (
                        <Button id="sharebtn" data-sharetext="আমার ব্যবসা প্রতিষ্ঠানের কার্ড" onClick={shareCard} variant="contained" className={classes.sharebtn}>
                          <ShareIcon />&nbsp;অন্য মাধ্যমে শেয়ার করি
                        </Button>
                      ) : ''}
                      <Button href={`/biz${href}&capture=${selectedFile}`} variant="contained" className={classes.sharebtn}>
                        <HomeIcon />&nbsp;হোম এ যাই
                      </Button>
                    </div>
                  ) : (
                    <Button id="bizcard" data-sharetext="আমার ব্যবসা প্রতিষ্ঠানের কার্ড" onClick={generateCard} variant="contained" className={classes.tkbtn}>
                      নিশ্চিত
                    </Button>
                  )}
                </div>
              ) : (
                <div>
                  <Swiper
                    effect="coverflow"
                    grabCursor
                    centeredSlides
                    slidesPerView="auto"
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    pagination
                    onSwiper={onSlideChange}
                    onSlideChange={onSlideChange}
                    className="mySwiper"
                  >
                    <SwiperSlide id="65"><img src="/cards/65.png" /></SwiperSlide>
                  </Swiper>
                  <div className={classes.labelOwnerTopMostRight}><b>{owner || ''}</b></div>
                  <div className={classes.labelMobileTopRight}>{mobile || ''}</div>
                  <div className={classes.labelBusinessBottomCenter}><b>{name || ''}</b></div>
                  <div className={classes.labelDescBottomCenter}>{desc || ''}</div>
                  <div className={classes.labelAddressBottomCenter}>{address || ''}</div>
                  <div className={classes.imagePreviewQR}><img src={selectedFile} className={classes.imagePreviewQRImg} /></div>
                  <TextField onChange={(event) => setOwner(event.target.value)} id="business_owner" margin="normal" fullWidth label="আপনার নাম*" variant="outlined" value={owner || ''} />
                  <TextField onChange={(event) => setMobile(event.target.value)} id="business_mobile" margin="normal" fullWidth label="আপনার মোবাইল নম্বর*" variant="outlined" value={mobile || ''} />
                  <TextField onChange={(event) => setName(event.target.value)} id="business_name" margin="normal" fullWidth label="ব্যবসা প্রতিষ্ঠানের নাম*" variant="outlined" value={name || ''} />
                  <TextField onChange={(event) => setDesc(event.target.value)} id="business_desc" margin="normal" fullWidth label="ব্যবসা প্রতিষ্ঠানের বিবরণ*" variant="outlined" value={desc || ''} />
                  <TextField onChange={(event) => setAddress(event.target.value)} id="business_address" margin="normal" fullWidth label="ব্যবসা প্রতিষ্ঠানের ঠিকানা*" variant="outlined" value={address || ''} />
                  <Button href={`/biz${href}&card=${selectedCard}&capture=${selectedFile}`} disabled={!((name && owner && address && mobile))} variant="contained" className={classes.tkbtn}>
                    পরবর্তী
                  </Button>
                </div>
              )}
            </Box>
          </Container>
        </Route>
        <Route path="/">
          <Container maxWidth="sm">
            <Box my={4}>
              <div className={classes.centerText}>
                <h1>টালিখাতা ডিজিটাল কার্ড</h1>
                <Button href="/halkhata" variant="contained" className={classes.tkbtn}>
                  হালখাতার কার্ড তৈরি করি
                </Button>
                <Button href="/newyear" variant="contained" className={classes.tkbtn}>
                  ইংরেজি নতুন বছরের কার্ড তৈরি করি
                </Button>
                <Button href="/feb21" variant="contained" className={classes.tkbtn}>
                  একুশে ফেব্রুয়ারির কার্ড তৈরি করি
                </Button>
                <Button href="/eid" variant="contained" className={classes.tkbtn}>
                  ঈদ-উল-ফিতরের কার্ড তৈরি করি
                </Button>
                <Button href="/kurbani" variant="contained" className={classes.tkbtn}>
                  ঈদ-উল-আযহার কার্ড তৈরি করি
                </Button>
                <Button href="/superqr" variant="contained" className={classes.tkbtn}>
                  সুপার QR কার্ড তৈরি করি
                </Button>
                <Button href="/biz" variant="contained" className={classes.tkbtn}>
                  ব্যবসা প্রতিষ্ঠানের কার্ড তৈরি করি
                </Button>
              </div>
            </Box>
          </Container>
        </Route>
      </Switch>
    </Router>
  );
}
