import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  centerText: {
    textAlign: 'center',
  },
  input: {
    display: 'none',
  },
  sharebox: {
    display: 'flex',
    flexDirection: 'column',
  },
  sharebtn: {
    marginTop: 20,
    color: 'white',
    backgroundColor: '#2196F3',
    width: '100%',
    height: 50,
    borderRadius: 100,
  },
  tkbtn: {
    marginTop: 20,
    color: 'white',
    backgroundColor: '#C4161C',
    width: '100%',
    height: 50,
    borderRadius: 100,
  },
  tkbtnupload: {
    marginTop: 10,
    marginBottom: 10,
    color: 'white',
    backgroundColor: '#2196F3',
    width: '100%',
    height: 50,
    borderRadius: 100,
  },
  finalCard: {
    marginTop: 82,
    marginBottom: 82,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 300,
    height: 158,
  },
  label: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 700,
    fontSize: 8,
    position: 'absolute',
    textAlign: 'left',
    color: '#c22932',
    width: 300,
    top: 212,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 5,
    paddingLeft: 10,
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelAddress: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 400,
    fontSize: 6,
    position: 'absolute',
    textAlign: 'left',
    color: '#c22932',
    width: 300,
    top: 222,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 5,
    paddingLeft: 10,
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelAddressBottomRight: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 400,
    fontSize: 10,
    position: 'absolute',
    textAlign: 'left',
    color: '#bababa',
    width: 150,
    top: 212,
    left: 125,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelLogo: {
    fontSize: 8,
    position: 'absolute',
    textAlign: 'right',
    width: 300,
    height: 38,
    top: 212,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelBusiness: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontSize: 8,
    position: 'absolute',
    width: 300,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    paddingLeft: 160,
    top: 166,
    textAlign: 'right',
    color: '#09101D',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelBusinessCenter: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 400,
    fontSize: 10,
    position: 'absolute',
    width: 300,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    top: 210,
    textAlign: 'center',
    color: '#000000',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelBusinessTopRight: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 400,
    fontSize: 10,
    position: 'absolute',
    width: 160,
    left: 140,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    top: 142,
    textAlign: 'center',
    color: '#000000',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelOwnerCenter: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 700,
    fontSize: 12,
    position: 'absolute',
    width: 300,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    top: 190,
    textAlign: 'center',
    color: '#09101D',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelOwnerTopRight: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 700,
    fontSize: 11,
    position: 'absolute',
    width: 160,
    left: 140,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    top: 125,
    textAlign: 'center',
    color: '#09101D',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelOwnerTopMostRight: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 700,
    fontSize: 11,
    position: 'absolute',
    width: 160,
    left: 130,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    top: 85,
    textAlign: 'right',
    color: '#09101D',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelMobileTopRight: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 400,
    fontSize: 11,
    position: 'absolute',
    width: 160,
    left: 130,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    top: 105,
    textAlign: 'right',
    color: '#09101D',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelBusinessRight: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 400,
    fontSize: 10,
    position: 'absolute',
    width: 160,
    left: 132,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    top: 175,
    textAlign: 'center',
    color: '#C4161C',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelBusinessLeft: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 400,
    fontSize: 10,
    position: 'absolute',
    width: 160,
    left: 0,
    right: 140,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    top: 200,
    textAlign: 'center',
    color: '#09101D',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelBusinessBottomRight: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 400,
    fontSize: 10,
    position: 'absolute',
    width: 150,
    left: 125,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    top: 195,
    textAlign: 'left',
    color: '#bababa',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelBusinessBottomCenter: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 700,
    fontSize: 16,
    position: 'absolute',
    width: 300,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    top: 170,
    padding: 5,
    textAlign: 'center',
    color: '#c4161c',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelDescBottomCenter: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 400,
    fontSize: 10,
    position: 'absolute',
    width: 300,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    top: 195,
    textAlign: 'center',
    color: '#000000',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelAddressBottomCenter: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 400,
    fontSize: 10,
    position: 'absolute',
    width: 300,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    top: 223,
    textAlign: 'center',
    color: '#ffffff',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelOwnerRight: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 700,
    fontSize: 12,
    position: 'absolute',
    width: 160,
    left: 132,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    top: 155,
    textAlign: 'center',
    color: '#C4161C',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelOwnerLeft: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 700,
    fontSize: 10,
    position: 'absolute',
    width: 160,
    left: 0,
    right: 140,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    top: 178,
    textAlign: 'center',
    color: '#C4161C',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelCustomer: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 400,
    fontSize: 10,
    position: 'absolute',
    width: 300,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    paddingRight: 140,
    top: 165,
    textAlign: 'left',
    color: '#09101D',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelBaki: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 700,
    fontSize: 12,
    position: 'absolute',
    width: 300,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    paddingRight: 140,
    top: 180,
    textAlign: 'left',
    color: '#09101D',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  labelDate: {
    fontFamily: "'Hind Siliguri', sans-serif",
    fontWeight: 700,
    fontSize: 8,
    position: 'absolute',
    width: 300,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 10,
    paddingRight: 140,
    top: 200,
    textAlign: 'left',
    color: '#09101D',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  imagePreview: {
    position: 'absolute',
    width: 115,
    height: 120,
    overflow: 'hidden',
    top: 105,
    left: 0,
    right: 160,
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  imagePreviewImg: {
    width: 120
  },
  imagePreviewQR: {
    position: 'absolute',
    width: 60,
    height: 60,
    overflow: 'hidden',
    top: 90,
    left: 0,
    right: 225,
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 2,
    background: 'transparent',
    letterSpacing: 0,
  },
  imagePreviewQRImg: {
    width: 60
  }
});

export default useStyles;
